import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ItemList from './ItemList';
import SearchBar from './SearchBar';
import { Box, Typography, Pagination, CircularProgress } from '@mui/material';

const ItemListContainer = ({ searchText, setSearchText }) => {
  const [productos, setProductos] = useState([]);
  const [filteredProductos, setFilteredProductos] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const [sortOption, setSortOption] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const itemsPerPage = 12;

    // Función para capitalizar cada palabra en un texto con excepciones
const capitalizeWords = (text) => {
  const exceptions = ['de', 'del', 'la', 'el', 'y', 'o', 'en', 'con', 'a', 'por', 'para'];
  return text
    .toLowerCase()
    .split(' ')
    .map((word, index) => 
      exceptions.includes(word) && index !== 0
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(' ');
};

  useEffect(() => {
    const fetchProductos = async () => {
        try {
            const response = await axios.post('https://milockers.cl/api/productos.php');
            if (response.data.success) {
                let productosFiltrados = response.data.data;

                // Normalizar las categorías y eliminar duplicados
                const allCategories = [...new Set(
                    productosFiltrados.map((prod) => capitalizeWords(prod.categoria.trim()))
                )];

                // Normalizar las categorías y títulos de los productos
                const productosConTextoNormalizado = productosFiltrados.map((prod) => ({
                    ...prod,
                    categoria: capitalizeWords(prod.categoria.trim()), // Normaliza la categoría
                    titulo: capitalizeWords(prod.titulo.trim()), // Normaliza el título
                }));

                setCategories(allCategories); // Ajusta categorías capitalizadas
                setProductos(productosConTextoNormalizado); // Ajusta productos normalizados
                setFilteredProductos(productosConTextoNormalizado);
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setError('Error al obtener los productos: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    fetchProductos();

    // Obtener parámetros de búsqueda de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('search');
    const category = urlParams.get('category');
    if (search) {
        setSearchText(search);
    }
    if (category) {
        setSelectedCategory(category);
    }
  }, [setSearchText]);


  useEffect(() => {
    let filteredData = productos.filter((item) =>
        item.titulo.toLowerCase().includes(searchText.toLowerCase().trim()) ||
        item.modelo.toLowerCase().includes(searchText.toLowerCase().trim())
    );

    if (sortOption === 'titleAsc') {
        filteredData.sort((a, b) => a.titulo.localeCompare(b.titulo));
    } else if (sortOption === 'titleDesc') {
        filteredData.sort((a, b) => b.titulo.localeCompare(a.titulo));
    } else if (sortOption === 'priceAsc') {
        filteredData.sort((a, b) => a.precio - b.precio);
    } else if (sortOption === 'priceDesc') {
        filteredData.sort((a, b) => b.precio - a.precio);
    }

    if (selectedCategory) {
        filteredData = filteredData.filter((item) => item.categoria === selectedCategory);
    }

    if (selectedBrands.length > 0) {
        filteredData = filteredData.filter((item) => selectedBrands.includes(item.marca));
    }

    setFilteredProductos(filteredData);
    setPage(1);

    // actualizar url 
    const url = new URL(window.location);
    url.searchParams.set('search', searchText);
    url.searchParams.set('category', selectedCategory? selectedCategory : '');
    window.history.pushState({}, '', url);
}, [searchText, sortOption, productos, selectedCategory, selectedBrands]);


  const handleCategoryChange = (e) => {
    const category = e.target.value; // Asegura que nunca sea undefined
    setSelectedCategory(category);
  };
  

  const handleBrandChange = (e) => {
    const brand = e.target.value;
    setSelectedBrands((prevSelectedBrands) =>
      prevSelectedBrands.includes(brand)
        ? prevSelectedBrands.filter((b) => b !== brand)
        : [...prevSelectedBrands, brand]
    );
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const paginatedProductos = filteredProductos.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Box>
      <SearchBar
        // searchText={searchText}
        // setSearchText={setSearchText}
        sortOption={sortOption}
        setSortOption={setSortOption}
        selectedBrands={selectedBrands}
        handleBrandChange={handleBrandChange}
        categories={categories}
        selectedCategory={selectedCategory}
        handleCategoryChange={handleCategoryChange}
      />
      {/* <Typography

      >
        Productos
      </Typography> */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
              <Typography color="error" variant="h6" component="p">
                ¡Oops! Parece que hubo un problema al cargar los productos.
              </Typography>
              <Typography variant="body1" component="p" sx={{ marginTop: '0.5rem' }}>
                Por favor, verifica tu conexión o inténtalo de nuevo en unos momentos.
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center' }}>
            <ItemList productos={paginatedProductos} />
          </Box>
          {filteredProductos.length > itemsPerPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <Pagination
                count={Math.ceil(filteredProductos.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ItemListContainer;
